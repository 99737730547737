import go from 'gojs'

import { LinkRoutingType } from '@/components/Diagram/types'
import colors from '@/styles/diagramColors'

// more arrows you can find here https://gojs.net/latest/samples/arrowheads.html
const arrows = {
	none: 'None',
	openTriangle: 'OpenTriangle',
	BackwardOpenTriangle: 'BackwardOpenTriangle',
}

export const setupLinkTemplates = (
	linkCallback?: (node: number) => void,
	selectedLinkInfo?: (link: number) => void,
	selectedLinksRoutingType?: string,
) => {
	const $ = go.GraphObject.make
	const linkTemplates = new go.Map<string, go.Link>()
	const commonBindings = [
		$(
			go.Shape,
			new go.Binding('stroke', 'color').makeTwoWay(),
			new go.Binding('stroke', 'hasChanged', (hasChanged) =>
				hasChanged ? colors.nodes.hasChanged : 'black',
			),
		),
		$(
			go.TextBlock,
			{ background: colors.nodes.background },
			new go.Binding('text'),
		),
	]

	let defaultLink: go.Link = new go.Link()

	/* there is some issue with current version of gojs and TS
    for go.Routing and go.Curve
 
   */
	if (selectedLinksRoutingType === LinkRoutingType.Orthogonal) {
		defaultLink = $(
			go.Link,
			{
				layerName: 'Background',
				routing: go.Routing?.AvoidsNodes,
				curve: go.Curve?.JumpOver,
				corner: 5,
				toShortLength: 4,
				relinkableFrom: true,
				relinkableTo: true,
				reshapable: true,
				resegmentable: true,
				click: function (obj) {
					const clickedLink = obj.diagram.findPartAt(obj.documentPoint)
					if (clickedLink !== null && selectedLinkInfo) {
						selectedLinkInfo(clickedLink.data)
					}
				},
				doubleClick: function (obj) {
					const clickedLink = obj.diagram.findPartAt(obj.documentPoint)
					if (clickedLink !== null && linkCallback) {
						linkCallback(clickedLink.data.from)
					}
				},
			},
			...commonBindings,
			$(
				go.Shape,
				new go.Binding('stroke', 'color').makeTwoWay(),
				new go.Binding('stroke', 'hasChanged', (hasChanged) =>
					hasChanged ? colors.nodes.hasChanged : 'black',
				),
				{ fromArrow: arrows.BackwardOpenTriangle },
			),
			$(
				go.Shape,
				{
					figure: 'Circle',
					desiredSize: new go.Size(8, 8),
					fill: 'red',
					stroke: null,
					opacity: 0.8,
					visible: false,
				},
				new go.Binding('visible', 'visible'),
			),
		)
	} else if (selectedLinksRoutingType === LinkRoutingType.Direct) {
		defaultLink = $(
			go.Link,
			{ layerName: 'Background' },
			{
				click: function (obj) {
					const clickedLink = obj.diagram.findPartAt(obj.documentPoint)
					if (clickedLink !== null && selectedLinkInfo) {
						selectedLinkInfo(clickedLink.data)
					}
				},
				doubleClick: function (obj) {
					const clickedLink = obj.diagram.findPartAt(obj.documentPoint)
					if (clickedLink !== null && linkCallback) {
						linkCallback(clickedLink.data.from)
					}
				},
			},
			...commonBindings,
			$(
				go.Shape,
				new go.Binding('stroke', 'color').makeTwoWay(),
				new go.Binding('stroke', 'hasChanged', (hasChanged) =>
					hasChanged ? colors.nodes.hasChanged : 'black',
				),
				{ fromArrow: arrows.BackwardOpenTriangle },
			),
		)
	}

	linkTemplates.add('', defaultLink)

	return linkTemplates
}
