import { ValueRow, ValueLabel, ReadonlyValue } from './styles'
import { DiagramPropertiesContentProps } from './types'

export const DiagramPropertiesContent = ({
	contents,
	propertiesWidth,
}: DiagramPropertiesContentProps) => {
	return (
		<div style={{ maxHeight: '15rem', overflowY: 'auto' }}>
			{contents.map(({ label, inputType, value, onChange }, id) => (
				<ValueRow key={id} $propertiesWidth={propertiesWidth}>
					<ValueLabel>{label}</ValueLabel>
					{inputType ? (
						<input
							type={inputType}
							value={typeof value === 'string' ? value : undefined}
							onChange={onChange}
						/>
					) : (
						<ReadonlyValue>{value}</ReadonlyValue>
					)}
				</ValueRow>
			))}
		</div>
	)
}
