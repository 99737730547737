export default {
	grid: {
		horizontalLineColor: '#D3D3D3',
		verticalLineColor: '#D3D3D3',
		intervalHorizontalLineColor: '#808080',
		intervalVerticalLineColor: '#808080',
	},
	nodes: {
		background: '#ffffff',
		border: '#3d3d3d',
		hasChanged: '#ff0000',
		headerBackground: ' #add8e6',
		bodyBackground: '#FFFFED',
		bodyColor: '#FFFFED',
	},
	links: {
		default: '#add8e6',
	},
}
