import React, {useState} from 'react'
import {ReactKeycloakProvider} from '@react-keycloak/web'
import {Root} from './Root'
import keycloak from '@/endpoints/keycloak'

export const Keycloak = () => {
    const [token, setToken] = useState<undefined | string>(undefined)

    const eventLogger = (event: unknown, error: unknown) => {
//        console.log('onKeycloakEvent', event, error)
    }

    return (
        <ReactKeycloakProvider
            initOptions={{onLoad: 'login-required', checkLoginIframe: true}}
            authClient={keycloak}
            onTokens={(tokens) =>
                setToken((prev) => (prev === tokens.token ? prev : tokens.token))
            }
            onEvent={eventLogger}
        >
            <Root token={token}/>
        </ReactKeycloakProvider>
    )
}
