import { ReactDiagram } from 'gojs-react'
import {
	ChangeEvent,
	LegacyRef,
	MutableRefObject,
	ReactNode,
	RefObject,
} from 'react'

import { StructureDto } from '@/endpoints/models'
import { NewDiagramData } from '@/endpoints/schemas/diagram'
import { OpenedFolderData } from '@/store/modules/folder/types'
import { StructureDtoRedux } from '@/store/modules/node'
import { NativeMap } from '@/utils'

const categories = {
	default: '',
	table: 'table',
}

export type Category = (typeof categories)[keyof typeof categories]

export type Node = {
	bodyColor?: string
	/**
	 * should be used to apply template
	 */
	category?: Category
	color?: string
	hasChanged?: boolean
	headerColor?: string
	key: number
	text?: string
	textBackground?: string
}

export type Link = {
	color?: string
	/**
	 * the key of node
	 */
	from: number
	key: number
	text?: string
	/**
	 * the key of node
	 */
	to: number
}

export type NewDiagramProps = {
	diagram?: any
	hasPalette?: boolean
	isEditMode?: boolean
	node: StructureDto
	nodeCallback?: (nodeId: number) => void
	onModelChange: (value: any) => void
	parsedLinks: ParsedLink[]
	parsedNodes: ParsedNode[]
	properties: any
	saveProperties?: (properties: any) => void
	selectedTabDetailId?: number
}

export type ValidateDiagramProps = {
	diagram: {
		exportPng: () => void
		exportSvg: () => void
		makeAutoLayout: () => void
		ref: React.MutableRefObject<ReactDiagram | null>
	}
	isEditMode: boolean | undefined
	nodeId: number
	onModelChange: () => void
	onValidationErrors: (errors: ValidationError[]) => void
	setShowModal: (value: boolean) => void
}

export enum ValidationErrorType {
	NODE_NOT_EXIST,
	TEXT_MISMATCH,
	LINK_INVALID,
}

export type ValidationError = {
	message: string
	nodeId: number
	nodeName: string
	type:
		| ValidationErrorType.NODE_NOT_EXIST
		| ValidationErrorType.TEXT_MISMATCH
		| ValidationErrorType.LINK_INVALID
}

export type DiagramModalsProps = {
	folderNode?: StructureDto
	handleClose: () => void
	handleUpdateDiagram: () => void
	isAddNewNode: boolean
	nodeToDelete?: StructureDto
	setIsAddNewNode: (show: boolean) => void
	setShowDeleteModal: (show: boolean) => void
	showDeleteModal: boolean
	showModal: boolean
	validationErrors: ValidationError[]
}

export type DiagramPropertiesPanelProps = {
	diagramRef: RefObject<ReactDiagram>
	nodeId: number
	saveProperties: ((properties: any) => void) | undefined
	selectedDiagram?: boolean
	selectedLink?: Link | null | number
	selectedNode?: Node | null
}

export type DiagramPropertiesProps = {
	diagramRef: RefObject<ReactDiagram>
	handlePropertiesTitle: (title: string) => void
	nodeId: number
	saveProperties: ((properties: any) => void) | undefined
}

export type DiagramGridProperties = {
	gridCellSize: number
	gridVisible: boolean
	horizontalLineColor: string
	intervalHorizontalLineColor: string
	intervalVerticalLineColor: string
	verticalLineColor: string
}

export type NodePropertiesProps = {
	diagramRef: RefObject<ReactDiagram>
	getAllNodesFromTree: NativeMap<StructureDtoRedux>
	handlePropertiesTitle: (title: string) => void
	propertiesWidth: number
	selectedNode: Node
}

export type DiagramPropertiesContentProps = {
	contents: Array<{
		inputType?: string
		label: string
		onChange?: (e: ChangeEvent<HTMLInputElement>) => void
		value: string | ReactNode
	}>
	propertiesWidth: number
}

export type NodeProperty = {
	id: number
	inputType?: 'color' | 'text' | 'number'
	label: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	value: string | number | undefined
}

export type LinkPropertiesProps = {
	diagramRef: MutableRefObject<ReactDiagram | null>
	getAllNodesFromTree: NativeMap<StructureDtoRedux>
	handlePropertiesTitle: (title: string) => void
	propertiesWidth: number
	selectedLink: any
}

export enum LinkRoutingType {
	Direct = 'direct',
	Orthogonal = 'orthogonal',
}

export type ColumnWithKeys = {
	code: string
	isPrimaryKey: boolean
	name: string
}
export type ForeignKey = {
	code: string
	constraintType: 'foreign_key'
	name: string
}
export type NodeWithKeys = {
	columns: ColumnWithKeys[]
	foreignKeys: ForeignKey[]
	id: number
	name: string
}

export interface ParsedLink {
	color?: string
	from: string | number
	hasChanged?: boolean
	routingType?: string
	text?: string
	to: string | number
}

export interface ParsedNode {
	category?: string
	key: number | undefined
}
