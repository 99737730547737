import go from 'gojs'
import { ReactDiagram, ReactPalette } from 'gojs-react'
import { ForwardedRef, forwardRef, useState } from 'react'

import { getNodeDetail } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { StructureDto } from '@/endpoints/models'
import { useAppContext, useAppStore } from '@/hooks'
import { RootState } from '@/store'
import colors from '@/styles/diagramColors'

import { DiagramControls } from './DiagramControls'
import { DiagramModals } from './DiagramModals'
import { DiagramPropertiesPanel } from './DiagramPropertiesPanel'
import { Container, DiagramContainer } from './styles'
import {
	setupDiagramContextMenu,
	setupLinkTemplates,
	setupNodeTemplates,
} from './Templates'
import {
	LinkRoutingType,
	NewDiagramProps,
	Node,
	ValidationError,
} from './types'
import { useReactDiagramDrop } from './useReactDiagramDrop'
import { useReactDiagramValidate } from './useReactDiagramValidate'
import {
	addNewNode,
	deleteNode,
	initPalette,
	markChanges,
	removeNode,
	useHandleData,
} from './utils'

export const Diagram = forwardRef(
	(
		{
			diagram,
			onModelChange,
			saveProperties,
			isEditMode,
			hasPalette,
			properties,
			parsedNodes,
			parsedLinks,
			node,
		}: NewDiagramProps,
		ref: ForwardedRef<any>,
	) => {
		const [idNode, setIdNode] = useState<number>(0)
		const [isLinkDblCliked, setIsLinkDblCliked] = useState(false)
		const [showModal, setShowModal] = useState(false)
		const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
			[],
		)
		const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
		const [nodeToDelete, setNodeToDelete] = useState<StructureDto | undefined>()
		const [folderNode, setFolderNode] = useState<StructureDto | undefined>()
		const [isAddNewNode, setIsAddNewNode] = useState(false)
		const [selectedNode, setSelectedNode] = useState<Node | null>(null)
		const [selectedLink, setSelectedLink] = useState<number | null>(null)
		const [selectedDiagram, setSelectedDiagram] = useState(true)
		const { data } = useApi(getNodeDetail(idNode))
		const { t } = useAppContext()
		const treeNodes = useAppStore((state: RootState) => state?.node?.nodes)
		const state = useAppStore((state: RootState) => state)

		const routingType = useAppStore(
			(state: RootState) =>
				state?.folder?.folders[node.id]?.form.newDiagram?.properties
					?.routingType,
		)

		const { handleDrop, handleDragOver, handleDragLeave } = useReactDiagramDrop(
			{
				getAllNodesFromTree: treeNodes,
				parsedNodes,
				diagram,
				state,
				onModelChange,
			},
		)

		const { validateTablesInDiagram, handleUpdateDiagram } =
			useReactDiagramValidate({
				nodeId: node.id,
				diagram,
				isEditMode,
				onModelChange,
				setShowModal,
				onValidationErrors: (errors) => {
					setValidationErrors(errors)
					if (errors.length > 0) {
						setShowModal(true)
						markChanges(diagram.ref.current, validationErrors)
					}
				},
			})

		// BUGGY CODE IT SHARES THE REF WITH THE DIAGRAM INSTANCE
		// useImperativeHandle(ref, () => ({
		// 	validateTablesInDiagram,
		// }))

		const onAutoLayout = () => {
			diagram.makeAutoLayout()
			onModelChange()
		}

		const nodeCallback = (nodeId: number) => {
			setIdNode(nodeId)
			setIsLinkDblCliked(false)
		}

		const selectedNodeInfo = (nodeId: number) => {
			if (diagram.ref.current) {
				const diagramInstance = diagram.ref.current.getDiagram()
				const model = diagramInstance?.model as go.GraphLinksModel
				const nodeData = model.findNodeDataForKey(nodeId)
				setSelectedNode(nodeData as Node)
				setSelectedLink(null)
				setSelectedDiagram(false)
			}
		}

		const selectedLinkInfo = (linkId: number) => {
			setSelectedLink(linkId)
			setSelectedNode(null)
			setSelectedDiagram(false)
		}

		const selectedDiagramInfo = () => {
			setSelectedNode(null)
			setSelectedLink(null)
			setSelectedDiagram(true)
		}

		const linkCallback = (nodeId: number) => {
			setIdNode(nodeId)
			setIsLinkDblCliked(true)
		}

		if (data) {
			useHandleData(data, isLinkDblCliked)
		}

		const handleClose = () => {
			const diagramRef = diagram.ref.current
			setShowModal(false)
			markChanges(diagramRef, validationErrors)
		}

		const allKeysFromNodes = useAppStore(
			(state) =>
				state?.folder?.folders[node.id]?.form.newDiagram?.allKeysFromNodes,
		)

		const initDiagram = () => {
			const $ = go.GraphObject.make
			const goJsDiagram = $(go.Diagram, {
				'undoManager.isEnabled': true,
				model: $(go.GraphLinksModel, {
					nodeKeyProperty: 'key',
					linkKeyProperty: 'key',
				}),
			})

			goJsDiagram.nodeTemplate = $(
				go.Node,
				'Auto',
				new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
					go.Point.stringify,
				),
			)

			goJsDiagram.nodeTemplateMap = setupNodeTemplates(
				allKeysFromNodes,
				treeNodes,
				nodeCallback,
				selectedNodeInfo,
				removeNode(diagram.ref),
				deleteNode(treeNodes, setShowDeleteModal, (node) =>
					setNodeToDelete(node as StructureDto),
				),
			)

			goJsDiagram.linkTemplateMap = setupLinkTemplates(
				linkCallback,
				selectedLinkInfo,
				routingType || LinkRoutingType.Orthogonal,
			)
			setupDiagramContextMenu(
				goJsDiagram,
				addNewNode(
					treeNodes,
					(node) => setFolderNode(node as StructureDto),
					node.id,
					setIsAddNewNode,
				),
				selectedDiagramInfo,
				t,
			)
			const gridVisible = properties?.gridVisible

			if (gridVisible) {
				const gridCellSize = properties?.gridCellSize || 10
				const horizontalLineColor =
					properties?.horizontalLineColor || colors.grid.horizontalLineColor
				const verticalLineColor =
					properties?.verticalLineColor || colors.grid.verticalLineColor
				const intervalHorizontalLineColor =
					properties?.intervalHorizontalLineColor ||
					colors.grid.intervalHorizontalLineColor
				const intervalVerticalLineColor =
					properties?.intervalVerticalLineColor ||
					colors.grid.intervalVerticalLineColor

				goJsDiagram.grid.visible = gridVisible
				goJsDiagram.undoManager.isEnabled = true
				goJsDiagram.grid = new go.Panel('Grid', {
					gridCellSize: new go.Size(gridCellSize, gridCellSize),
				}).add(
					new go.Shape('LineH', {
						name: 'HorizontalLine',
						stroke: horizontalLineColor,
					}),
					new go.Shape('LineV', {
						name: 'VerticalLine',
						stroke: verticalLineColor,
					}),
					new go.Shape('LineH', {
						name: 'IntervalHorizontalLine',
						stroke: intervalHorizontalLineColor,
						interval: 5,
					}),
					new go.Shape('LineV', {
						name: 'IntervalVerticalLine',
						stroke: intervalVerticalLineColor,
						interval: 5,
					}),
				)
			}

			return goJsDiagram
		}

		return (
			<Container>
				<DiagramControls
					onExportPng={diagram.exportPng}
					onExportSvg={diagram.exportSvg}
					onAutoLayout={onAutoLayout}
				/>
				<DiagramContainer
					onDrop={handleDrop}
					onDragEnter={handleDragOver}
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDragEnd={handleDragLeave}
				>
					{hasPalette && parsedNodes && (
						<ReactPalette
							divClassName="palette"
							initPalette={initPalette}
							nodeDataArray={parsedNodes}
						/>
					)}

					<ReactDiagram
						ref={ref}
						divClassName="diagram"
						initDiagram={initDiagram}
						nodeDataArray={parsedNodes}
						linkDataArray={parsedLinks}
						onModelChange={onModelChange}
					/>

					<DiagramModals
						showModal={showModal}
						validationErrors={validationErrors}
						handleUpdateDiagram={handleUpdateDiagram}
						handleClose={handleClose}
						showDeleteModal={showDeleteModal}
						nodeToDelete={nodeToDelete}
						setShowDeleteModal={setShowDeleteModal}
						isAddNewNode={isAddNewNode}
						folderNode={folderNode}
						setIsAddNewNode={setIsAddNewNode}
					/>

					<DiagramPropertiesPanel
						selectedDiagram={selectedDiagram}
						selectedNode={selectedNode}
						selectedLink={selectedLink}
						diagramRef={diagram.ref}
						saveProperties={saveProperties}
						nodeId={node.id}
					/>
				</DiagramContainer>
			</Container>
		)
	},
)
