import { useEffect, useMemo, useRef } from 'react'
import { createTemplate, getTemplate, updateTemplate } from 'src/endpoints'

import { Tabs } from '@/components'
import { ModalForm } from '@/components/Modal/ModalForm'
import { TabProps } from '@/components/Tabs'
import { Form } from '@/components/UberForm'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import { TemplateDto } from '@/endpoints/models'
import { TemplateTab } from '@/enums'
import { useAppContext } from '@/hooks'

import { TemplateForm } from './TemplateForm'
import { TemplateModalProps } from './types'

export const TemplateModal = ({
	template,
	onSave,
	onClose,
	textAreaHeight,
}: TemplateModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const refForm = useRef<Form<TemplateDto>>(null)

	const templateDetail = useApi(getTemplate(template?.id as number))

	const handleSubmit = async (form: Partial<TemplateDto>) => {
		const {
			data,
			description,
			technologyId,
			comment,
			stereotypeId,
			objectSettingsId,
			structureType,
			formatType,
			fileExtension,
		} = form

		const payload = {
			data,
			description,
			technology: {
				id: technologyId,
			},
			stereotype: {
				id: stereotypeId,
			},
			objectSettings: {
				id: objectSettingsId,
			},
			comment,
			structureType,
			formatType,
			fileExtension,
		}

		const action =
			template && template.id
				? updateTemplate(template.id, payload)
				: createTemplate(payload)

		const result = await request(action)

		if (result) {
			templateDetail.invalidate()
			onSave()
			onClose()
		}
	}

	const formData = useMemo(
		() => templateDetail?.data || ({} as TemplateDto),
		[templateDetail],
	)

	const initialValues = useMemo(
		() => ({
			technologyId: formData.technology?.id,
			stereotypeId: formData.stereotype?.id,
			structureType: formData.structureType,
			comment: formData.comment,
			description: formData.description,
			fileExtension: formData.fileExtension,
			data: formData.data,
			formatType: formData.formatType,
			objectSettingsId: formData.objectSettings?.id,
			version: formData.version,
		}),
		[formData],
	)

	const tabs = useMemo((): TabProps[] => {
		return [
			{
				id: TemplateTab.Form,
				title: 'Form',
				content: (
					<TemplateForm
						formData={formData}
						template={template}
						textAreaHeight={textAreaHeight}
					/>
				),
			},
		]
	}, [])

	const modalHeader = () => (
		<>{template ? t('TEMPLATE_UPDATE') : t('TEMPLATE_NEW')}</>
	)

	useEffect(() => {
		refForm.current?.setValues(initialValues)
	}, [initialValues])

	return (
		<>
			<ModalForm<TemplateDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: 1000,
					minWidth: 1000,
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column',
				}}
				resizable
				initialValues={initialValues}
				formRef={refForm}
				header={modalHeader}
				onSubmit={handleSubmit}
			>
				<Tabs tabs={tabs} />
			</ModalForm>
		</>
	)
}
