import { Column } from '@tanstack/react-table'
import { useCallback, useState } from 'react'

import { Table } from '@/components'
import { Select } from '@/components/UberForm'
import { ReleaseDto, StructureDto } from '@/endpoints/models'
import { TabName } from '@/enums'
import { useAppContext, useAppDispatch } from '@/hooks'
import { removeDiagram, saveFolder } from '@/store/modules/folder/actions'
import { openTab } from '@/store/modules/tab/actions'

import { DeleteModal } from '../SystemDetail/pages/DeploymentPackages/components'

interface DiagramsTableProps {
	columns: Column<any>[]
	data: ReleaseDto[] | undefined
	editMode: boolean
	hasLastRowEdit: boolean
	node: StructureDto
}

export const DiagramsTable = ({
	columns,
	data,
	editMode,
	node,
}: DiagramsTableProps) => {
	const dispatch = useAppDispatch()
	const [isOpen, setIsOpen] = useState(false)
	const [selectedItem, setSelectedItem] = useState<any | null>(null)
	const [envOption, setEnvOption] = useState<any>()
	const { t } = useAppContext()

	const onDelete = (index: number) => {
		setIsOpen(true)
		setSelectedItem(index)
	}

	const confirmDelete = () => {
		const updatedData = data?.filter(
			(diagram) => diagram.id !== selectedItem?.id,
		)

		dispatch(removeDiagram(node, updatedData))

		dispatch(saveFolder(node))
	}
	const handleEdit = useCallback((diagram: ReleaseDto) => {
		dispatch(
			openTab(node, false, true, {
				id: diagram.id as number,
				customTabName: TabName.Diagram,
				name: diagram.name,
				parentNode: node,
				editMode: true,
			}),
		)
	}, [])

	const onChange = (e: any) => {
		setEnvOption(e)
	}

	return (
		<>
			<div className="mb-4" style={{ maxWidth: '12.5rem' }}>
				<label className="mb-1 self-start">{t('STATUS')}</label>
				<Select
					options={[{ value: 'ALL', label: 'ALL' }]}
					onChange={onChange}
					value={envOption}
					clearable={false}
				/>
			</div>

			<Table
				columns={columns}
				data={data ?? []}
				maxWidth={1200}
				isEditMode={editMode}
				onDelete={onDelete}
				onEdit={handleEdit}
				hasLastRowEdit
				hasLeftAction={false}
			/>
			{isOpen && (
				<DeleteModal
					object={selectedItem}
					deleteRequest={confirmDelete}
					onClose={() => {
						setIsOpen(false)
					}}
				/>
			)}
		</>
	)
}
