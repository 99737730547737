import go from 'gojs'

import { getNodePath } from '@/components/Diagram/utils'
import { AllKeysFromNodes } from '@/endpoints/schemas/diagram'
import { StructureDtoRedux } from '@/store/modules/node'
import colors from '@/styles/diagramColors'
import { NativeMap } from '@/utils'

// more shapes you can find here https://gojs.net/latest/samples/shapes.html
const shapes = {
	rectangle: 'Rectangle',
	lineH: 'LineH',
}

export const setupNodeTemplates = (
	allKeysFromNodes: AllKeysFromNodes[] | undefined,
	treeNodes: NativeMap<StructureDtoRedux>,
	nodeCallback?: (nodeId: number) => void,
	selectedNodeInfo?: (nodeId: number) => void,
	removeNodeCallback?: (nodeId: number) => void,
	deleteNodeCallback?: (nodeId: number) => void,
) => {
	const $ = go.GraphObject.make
	const nodeTemplates = new go.Map<string, go.Part>()

	const getAllColumns = (nodeId: number): { code: string; name: string }[] => {
		const node = Object.values(allKeysFromNodes).find(
			(node) => node.id === nodeId,
		)

		//If nodes havent columns it add empty columns for better nodes interface

		// If node is not found or node.columns is undefined, create a default array
		if (!node || !node.columns) {
			return Array(4).fill({ code: '', name: '', isPrimaryKey: false })
		}

		// If node.columns is an empty array, create a default array
		if (node.columns.length === 0) {
			return Array(4).fill({ code: '', name: '', isPrimaryKey: false })
		}

		// Otherwise, return the existing columns
		return node.columns.map((column) => ({
			code: column.code,
			name: column.name,
		}))
	}

	const getCommentAndDescription = (
		nodeId: string,
	): { comment: string; description: string } => {
		const node = allKeysFromNodes?.find((node) => node.id.toString() === nodeId)

		if (!node) {
			return { comment: '', description: '' }
		}

		return {
			comment: node.comment || '',
			description: node.description || '',
		}
	}

	const defaultNode = $(
		go.Node,
		go.Panel.Auto,
		$(
			go.Shape,
			shapes.rectangle,
			{ fill: 'white' },
			new go.Binding('fill', 'color').makeTwoWay(),
			new go.Binding('fill', 'hasChanged', (hasChanged) =>
				hasChanged ? colors.nodes.hasChanged : colors.nodes.background,
			),
		),
		$(go.TextBlock, { margin: 8 }, new go.Binding('text')),
	)

	nodeTemplates.add('', defaultNode)

	const tableNode = $(
		go.Node,
		'Auto',
		new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
			go.Point.stringify,
		),
		{
			locationSpot: go.Spot.Center,
			movable: true,
			contextMenu: $(
				'ContextMenu',
				$('ContextMenuButton', $(go.TextBlock, 'Remove table'), {
					click: function (_e, obj) {
						const node = obj.part
						if (node !== null) {
							if (removeNodeCallback) {
								removeNodeCallback(node.data.key)
							}
						}
					},
				}),
				$('ContextMenuButton', $(go.TextBlock, 'Delete table'), {
					click: function (_e, obj) {
						const node = obj.part
						if (node !== null) {
							if (deleteNodeCallback) {
								deleteNodeCallback(node.data.key)
							}
						}
					},
				}),
			),
			toolTip: $(
				'ToolTip',
				$(
					go.TextBlock,
					{ margin: 4, width: 140 },
					new go.Binding('text', '', (data) => {
						return (
							data.text + ':\n\n' + (getNodePath(treeNodes, data.key) || '')
						)
					}),
				),
			),

			click: function (obj) {
				const clickedNode = obj.diagram.findPartAt(obj.documentPoint)
				if (clickedNode !== null) {
					if (selectedNodeInfo) {
						selectedNodeInfo(clickedNode.data.key)
					}
				}
			},

			doubleClick: function (obj) {
				const clickedNode = obj.diagram.findPartAt(obj.documentPoint)
				if (clickedNode !== null) {
					if (nodeCallback) {
						nodeCallback(clickedNode.data.key)
					}
				}
			},
		},

		$(
			go.Shape,
			shapes.rectangle,
			{
				stroke: colors.nodes.border,
				fill: colors.nodes.bodyBackground,
				strokeWidth: 1,
			},
			new go.Binding('fill', 'color').makeTwoWay(),
			new go.Binding('fill', 'hasChanged', (hasChanged) =>
				hasChanged ? colors.nodes.hasChanged : colors.nodes.background,
			),
		),
		$(
			go.Panel,
			'Vertical',
			{ background: colors.nodes.bodyBackground },
			new go.Binding('background', 'bodyColor'),
			$(
				go.Panel,
				'Vertical',
				$(
					go.Panel,
					'Auto',
					{ margin: 1, background: colors.nodes.headerBackground },
					new go.Binding('background', 'headerColor'),
					$(
						go.TextBlock,
						{
							margin: 3,
							font: 'bold 16px sans-serif',
							width: 140,
							textAlign: 'center',
						},
						new go.Binding('text', '', (data) => {
							let text = ''
							if (data.tableCodeVisibility && data.tableNameVisibility) {
								text = `${data.text || ''} (ID: ${data.key || ''})`
							} else if (data.tableCodeVisibility) {
								text = `ID: ${data.key || ''}`
							} else if (data.tableNameVisibility) {
								text = data.text || ''
							}
							return text
						}),
					),
				),
				$(
					go.Panel,
					'Auto',
					{ margin: 1 },
					new go.Binding('background', 'bodyColor'),
					$(
						go.TextBlock,
						{
							margin: 3,
							font: '12px sans-serif',
							width: 140,
							textAlign: 'center',
						},
						new go.Binding('text', 'bodyText'),
					),
					go.Panel.Table,
					{
						itemTemplate: $(
							go.Panel,
							'TableRow',
							new go.Binding('row'),
							$(
								go.TextBlock,
								{
									margin: new go.Margin(0, 2),
									textAlign: 'left',
									stretch: go.GraphObject.Horizontal,
								},
								new go.Binding('text', '', function (column, panel) {
									let text = ''
									const nodeData = panel.part.data
									if (column) {
										if (
											nodeData.columnsNameVisibility &&
											nodeData.columnsCodeVisibility
										) {
											text = `${column.name} (${column.code})`
										} else if (nodeData.columnsNameVisibility) {
											text = column.name
										} else if (nodeData.columnsCodeVisibility) {
											text = column.code
										}
									}

									return text.trim()
								}),
							),
						),
						stretch: go.GraphObject.Horizontal,
					},
					$(
						go.Panel,
						'Auto',
						{ margin: 1 },
						go.Panel.Table,
						new go.Binding('itemArray', '', function (data) {
							return getAllColumns(data.key)
						}),
						{
							itemTemplate: $(
								go.Panel,
								'TableRow',
								new go.Binding('row'),
								$(
									go.TextBlock,
									{
										margin: new go.Margin(0, 2),
										textAlign: 'left',
										stretch: go.GraphObject.Horizontal,
									},
									new go.Binding('text', '', function (column, panel) {
										let text = ''
										const nodeData = panel.part.data
										if (column) {
											if (
												nodeData.columnsNameVisibility &&
												nodeData.columnsCodeVisibility
											) {
												text = `${column.name} (${column.code})`
											} else if (nodeData.columnsNameVisibility) {
												text = column.name
											} else if (nodeData.columnsCodeVisibility) {
												text = column.code
											}
										}

										return text.trim()
									}),
								),
							),
						},
					),
				),
				$(
					go.Panel,
					'Auto',
					{ margin: 0 },
					new go.Binding('background', 'bodyColor'),
					$(
						go.TextBlock,
						{ row: 0, column: 0 },
						new go.Binding('text', '', (data) => {
							const comment = getCommentAndDescription(
								data.key.toString(),
							).comment
							return comment ? comment : ''
						}),
						new go.Binding('visible', 'commentsVisibility'),
					),
					new go.Binding('background', 'bodyColor'),
				),

				$(
					go.Panel,
					'Auto',
					{ margin: 0 },
					$(
						go.TextBlock,
						{ row: 0, column: 0 },
						new go.Binding('text', '', (data) => {
							const description = getCommentAndDescription(
								data.key.toString(),
							).description
							return description ? description : ''
						}),
						new go.Binding('visible', 'descriptionsVisibility'),
					),
					new go.Binding('background', 'bodyColor'),
				),
			),
		),
	)
	nodeTemplates.add('table', tableNode)

	return nodeTemplates
}
