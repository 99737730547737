import styled from 'styled-components'
import { LinkRoutingType } from './types'
import { TranslationHelper } from '@/context/Locale'

export const RoutingTypeSelector = ({
	routingType,
	handleRoutingTypeChange,
	t,
}: {
	handleRoutingTypeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	t: TranslationHelper
	routingType: string
}) => {
	return (
		<RoutingTypeContainer>
			<RoutingTypeLabel>{t('DIAGRAM_ROUTING_TYPE')}</RoutingTypeLabel>
			<RadioGroup>
				<RadioLabel>
					<RadioInput
						type="radio"
						name="diagram_routing_type"
						value={LinkRoutingType.Direct}
						checked={routingType === LinkRoutingType.Direct}
						onChange={handleRoutingTypeChange}
					/>
					{t('DIAGRAM_ROUTING_TYPE_DIRECT')}
				</RadioLabel>
				<RadioLabel>
					<RadioInput
						type="radio"
						name="diagram_routing_type"
						value={LinkRoutingType.Orthogonal}
						checked={routingType === LinkRoutingType.Orthogonal}
						onChange={handleRoutingTypeChange}
					/>
					{t('DIAGRAM_ROUTING_TYPE_ORTHOGONAL')}
				</RadioLabel>
			</RadioGroup>
		</RoutingTypeContainer>
	)
}
const RoutingTypeContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-left: 0.5rem;
`

const RoutingTypeLabel = styled.label`
	margin-bottom: 0.5rem;
`

const RadioGroup = styled.div`
	display: flex;
	align-items: center;
`

const RadioLabel = styled.label`
	margin-right: 1rem;
	display: flex;
	align-items: center;
`

const RadioInput = styled.input`
	margin-right: 0.5rem;
`
