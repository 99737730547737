import { DiagramData } from '@/endpoints/schemas/diagram'
import { updateTabData } from '@/store/utils'
import { NativeMap } from '@/utils/collections'

import { ensureValidData } from '../helpers'
import {
	ADD_NEW_DIAGRAM,
	DIAGRAM_UPDATE,
	FOLDER_INIT,
	FOLDER_SAVE,
	FOLDER_UPDATE,
	REMOVE_DIAGRAM,
} from './constants'
import {
	defaultFolderData,
	getFolderDataForm,
	getInitialFolderData,
} from './helpers'
import { Action, FolderData, OpenedFolderData, OpenedFolderTab } from './types'

type State = Readonly<typeof initialState>

const initialState = {
	folders: {} as NativeMap<OpenedFolderData>,
}

export default (state = initialState, action: Action): State => {
	switch (action.type) {
		case FOLDER_INIT: {
			const { node, editMode, force } = action

			const previous = state.folders[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: FolderData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultFolderData)
				: getInitialFolderData(node.name)

			const folderDataForm = getFolderDataForm(data)

			return {
				...state,
				folders: {
					...state.folders,
					[node.id]: {
						form: folderDataForm,
						dirty: false,
						parsedEditMode: editMode,
						tab: previous ? previous.tab : OpenedFolderTab.Overview,
					},
				},
			}
		}

		case FOLDER_UPDATE: {
			const { node, payload } = action

			return {
				...state,
				folders: updateTabData(state.folders, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						...payload,
						diagram: {
							...node.form.diagram,
							...payload.diagram,
						} as DiagramData,
					},
					dirty: true,
				})),
			}
		}

		case ADD_NEW_DIAGRAM: {
			const { nodeId, update } = action

			return {
				...state,
				folders: updateTabData(state.folders, nodeId, (node) => {
					// Determine the next id based on the existing diagrams
					const currentDiagrams = state.folders[nodeId]?.form.diagrams || []
					// Calculate the next id
					let nextId =
						currentDiagrams.length > 0
							? currentDiagrams[currentDiagrams.length - 1].id + 1
							: 1

					// If nextId is NaN, reset it to 1
					nextId = isNaN(nextId) ? 1 : nextId

					// Update the id property for the new diagram
					const updatedDiagram = {
						...update,
						id: nextId,
					}

					return {
						...node,
						form: {
							...node.form,
							diagrams: [...currentDiagrams, { ...updatedDiagram }],
						},
						dirty: true,
					}
				}),
			}
		}

		case REMOVE_DIAGRAM: {
			const { node, update } = action

			return {
				...state,
				folders: updateTabData(state.folders, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						diagrams: [...update],
					},
					dirty: true,
				})),
			}
		}

		case DIAGRAM_UPDATE: {
			const { node, payload } = action

			console.log('state', state)

			const diagramIndex = state.folders[node.id]?.form?.diagrams?.findIndex(
				(diagram) => diagram.id === payload.selectedTabDetailId,
			)

			console.log('diagramIndex', diagramIndex)

			// If diagram with the given id is found, update it
			if (diagramIndex !== -1) {
				const updatedDiagrams = state.folders[node.id]?.form?.diagrams?.map(
					(diagram, index) =>
						index === diagramIndex
							? { ...diagram, ...payload.modifiedDiagramModel }
							: diagram,
				)

				return {
					...state,
					folders: updateTabData(state.folders, node.id, (node) => {
						return {
							...node,
							form: {
								...node.form,
								diagrams: updatedDiagrams,
							},
							dirty: true,
						}
					}),
				}
			}
			return state
		}

		case FOLDER_SAVE: {
			const {
				metadata: { nodeId },
			} = action

			return {
				...state,
				folders: updateTabData(state.folders, nodeId, (node) => ({
					...node,
					dirty: false,
				})),
			}
		}

		default: {
			return state
		}
	}
}
