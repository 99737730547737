import debounce from 'debounce'
import go from 'gojs'
import { useCallback } from 'react'

import { Diagram } from '@/components'
import { useReactDiagram } from '@/components/Diagram/useReactDiagram'
import { useAppDispatch } from '@/hooks'
import { FolderDiagramProps } from '@/pages/User/pages/Home/pages/FolderDetail/components/types'
import { saveFolder, updateDiagram } from '@/store/modules/folder/actions'

import { useParseNodesAndLinks } from '../utils'

export const FolderDiagram = ({
	node,
	isEditMode,
	selectedTabDetailId,
}: FolderDiagramProps) => {
	const dispatch = useAppDispatch()
	const diagram = useReactDiagram({ isEditMode })

	const diagramProperties = node

	const saveDebounced = debounce(() => dispatch(saveFolder(node)), 1000)

	const onModelChange = useCallback(
		(value: any) => {
			const { modifiedNodeData } = value
			const diagramInstance = diagram.ref?.current?.getDiagram()

			const getModelAsObject = (diagram: go.Diagram) => {
				const modelAsJson = diagram.model.toJson()
				return modelAsJson ? JSON.parse(modelAsJson) : undefined
			}

			const modifiedDiagramModel = getModelAsObject(
				diagramInstance as go.Diagram,
			)

			const payload = {
				modifiedDiagramModel,
				selectedTabDetailId,
			}

			if (modifiedNodeData?.length > 0) {
				dispatch(updateDiagram(node, payload))
				saveDebounced()
			}
		},
		[diagram.ref, dispatch, node, saveDebounced, selectedTabDetailId],
	)

	const { parsedNodes, parsedLinks } = useParseNodesAndLinks(
		node.id,
		selectedTabDetailId,
	)

	return (
		<Diagram
			node={node}
			ref={diagram.ref}
			onModelChange={onModelChange}
			saveProperties={() => console.log('save')}
			isEditMode={isEditMode}
			properties={diagramProperties}
			parsedNodes={parsedNodes}
			parsedLinks={parsedLinks}
			diagram={diagram}
		/>
	)
}
