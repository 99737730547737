import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { ModalForm } from '@/components/Modal/ModalForm'
import { TextAreaFormField, TextFormField } from '@/components/UberForm'
import { StructureDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'
import { addNewDiagram, saveFolder } from '@/store/modules/folder/actions'

interface AddDiagramProps {
	editMode: boolean
	node: StructureDto
	onClose: () => void
}

interface AddDiagramFormProps {
	description?: string
	name: string
}
export const AddDiagram = ({ node, onClose }: AddDiagramProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const handleSubmit = async (form: AddDiagramFormProps) => {
		const newDiagramPayload = {
			name: form.name,
			description: form.description,
			properties: {
				gridVisible: true,
				gridCellSize: 26,
				intervalHorizontalLineColor: '#2e3838',
				intervalVerticalLineColor: '#332e2e',
				verticalLineColor: '#f7e9e9',
				tableNameVisibility: true,
				tableCodeVisibility: true,
				horizontalLineColor: '#d5c8df',
				pkColumnsNameVisibility: true,
				columnsCodeVisibility: true,
				columnsNameVisibility: true,
				commentsVisibility: true,
				descriptionVisibility: true,
				pkColumnsCodeVisibility: true,
				isGridVisible: false,
				nodeHeaderColor: '#7e99a0',
			},
			class: 'GraphLinksModel',
			linkKeyProperty: 'key',
			nodeDataArray: [],
			linkDataArray: [],
		}

		dispatch(addNewDiagram(node.id, newDiagramPayload))
		await dispatch(saveFolder(node))
		onClose()
	}

	return (
		<ModalForm<AddDiagramFormData>
			saveTitle={t('CREATE')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '25rem',
			}}
			header={t('ADD_NEW_DIAGRAM')}
		>
			<TextFormField
				title={t('NAME')}
				name="name"
				required
				autoFocus
				enableAutocomplete={false}
			/>

			<TextAreaFormField title={t('DESCRIPTION')} name="description" />
		</ModalForm>
	)
}
