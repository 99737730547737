import go from 'gojs'

import { TranslationHelper } from '@/context/Locale'

export const setupDiagramContextMenu = (
	diagram: go.Diagram,
	addNewTable: () => void,
	selectedDiagramInfo: (value: boolean) => void,
	t: TranslationHelper,
) => {
	const $ = go.GraphObject.make

	diagram.contextMenu = $(
		'ContextMenu',
		$(
			'ContextMenuButton',
			{
				click: function () {
					if (addNewTable) {
						addNewTable()
					}
				},
			},
			$(go.TextBlock, t('DIAGRAM_ADD_TABLE')),
		),
	)
	diagram.addDiagramListener('BackgroundSingleClicked', function (e) {
		selectedDiagramInfo(true)
	})
}
