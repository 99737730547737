import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { MutableRefObject } from 'react'

import { ParsedNode } from '@/components/Diagram/types'
import { LinkData } from '@/endpoints/schemas/diagram'
import { TREE_PANEL_DRAG_DROP_NODE_ID } from '@/pages/User/pages/Home/components/TreePanel/drag'
import { RootState } from '@/store'

import { getLinksByNodeId } from './utils'

type DiagramOptions = {
	diagram: {
		exportPng: () => void
		exportSvg: () => void
		makeAutoLayout: () => void
		ref: MutableRefObject<ReactDiagram | null>
	}
	getAllNodesFromTree?: any
	onModelChange: () => void
	parsedNodes?: ParsedNode[]
	state: RootState
}

export const useReactDiagramDrop = ({
	getAllNodesFromTree,
	parsedNodes,
	diagram,
	onModelChange,
	state,
}: DiagramOptions) => {
	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		const droppedNodeId = e.dataTransfer.getData(TREE_PANEL_DRAG_DROP_NODE_ID)
		const droppedNode =
			getAllNodesFromTree && getAllNodesFromTree[droppedNodeId]

		if (
			parsedNodes?.find(
				(n: { key: number | undefined }) => n.key === droppedNode?.id,
			)
		) {
			return
		} else {
			const instanceDiagram = diagram.ref.current?.getDiagram()
			const model = instanceDiagram?.model as go.GraphLinksModel

			if (!model || !instanceDiagram || !instanceDiagram.div) return

			const diagramRect = instanceDiagram.div.getBoundingClientRect()
			const offsetX = e.clientX - diagramRect.left
			const offsetY = e.clientY - diagramRect.top

			// Convert the client coordinates to diagram coordinates
			const docPoint = instanceDiagram.transformViewToDoc(
				new go.Point(offsetX, offsetY),
			)

			const loc = `${docPoint?.x} ${docPoint?.y}`

			instanceDiagram.startTransaction('new node')

			model.addNodeData({
				key: droppedNode.id,
				text: droppedNode.name,
				category: 'table',
				loc: loc,
			})

			const linksForNode = getLinksByNodeId(state, droppedNode.id)
			linksForNode.forEach((link: LinkData) => {
				const existingLinks = model.linkDataArray
				const linkExists = existingLinks.some(
					(existingLink) =>
						existingLink.from === link.from && existingLink.to === link.to,
				)

				if (!linkExists) {
					model.addLinkData({
						from: link.from,
						to: link.to,
						text: link.text,
					})
				}
			})

			instanceDiagram.commitTransaction('new node')
			onModelChange()
		}
	}

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	return {
		handleDrop,
		handleDragOver,
		handleDragLeave,
	}
}
