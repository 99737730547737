import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from '@/components'
import { useAppContext } from '@/hooks'
import { CollaborativeUpdateTable } from './CollaborativeUpdateTable'
import { ValidationError } from '@/components/Diagram/types'

type CollaborativeUpdateAlertProps = {
	errors: ValidationError[]
	onUpdateDiagram: () => void
	onClose: () => void
}

export const CollaborativeUpdateAlert = ({
	errors,
	onUpdateDiagram,
	onClose,
}: CollaborativeUpdateAlertProps) => {
	const { t } = useAppContext()
	return (
		<Modal
			open={true}
			contentStyle={{ width: '30rem' }}
			header={t('DIAGRAM_CHANGELOG')}
			footer={(close) => (
				<>
					<Button icon={faRefresh} schema="success" onClick={onUpdateDiagram}>
						{t('UPDATE_DIAGRAM')}
					</Button>
					<Button schema="transparent" onClick={close} icon={faTimes}>
						{t('IGNORE')}
					</Button>
				</>
			)}
			onClose={onClose}
		>
			<CollaborativeUpdateTable errors={errors} />
		</Modal>
	)
}
