import { DeleteModal } from '@/pages/User/pages/Home/components/DeleteModal'
import { AddModal } from '@/pages/User/pages/Home/components/AddModal'
import { ReactNode } from 'react'
import { StructureObjectDto } from '@/endpoints/models'
import { CollaborativeUpdateAlert } from '@/pages/User/pages/Home/components/CollaborativeUpdateAlert'
import { DiagramModalsProps } from './types'

/**
 * Modal components for the Diagram
 * @param showModal - Whether to show the CollaborativeUpdateAlert modal
 * @param validationErrors - An array of validation errors
 * @param handleUpdateDiagram - A function to handle updating the diagram
 * @param handleClose - A function to handle closing the CollaborativeUpdateAlert modal
 * @param showDeleteModal - Whether to show the DeleteModal modal
 * @param nodeToDelete - The node to be deleted, if any
 * @param setShowDeleteModal - A function to set whether the DeleteModal modal is shown
 * @param isAddNewNode - Whether to show the AddModal modal
 * @param folderNode - The folder node to create a new node in, if any
 * @param setIsAddNewNode - A function to set whether the AddModal modal is shown
 * @returns The Modals component
 */
export const DiagramModals = (props: DiagramModalsProps): ReactNode => {
	const {
		showModal,
		validationErrors,
		handleUpdateDiagram,
		handleClose,
		showDeleteModal,
		nodeToDelete,
		setShowDeleteModal,
		isAddNewNode,
		folderNode,
		setIsAddNewNode,
	} = props

	return (
		<>
			{showModal && (
				<CollaborativeUpdateAlert
					errors={validationErrors}
					onUpdateDiagram={handleUpdateDiagram}
					onClose={handleClose}
				/>
			)}
			{showDeleteModal && nodeToDelete && (
				<DeleteModal
					node={nodeToDelete}
					onClose={() => setShowDeleteModal(false)}
				/>
			)}
			{isAddNewNode && folderNode && (
				<AddModal
					node={folderNode}
					type={StructureObjectDto.TypeEnum.TABLE}
					onClose={() => setIsAddNewNode(false)}
				/>
			)}
		</>
	)
}
