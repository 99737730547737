import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'

import { useAppContext, useAppStore } from '@/hooks'
import { RootState } from '@/store'

import { DiagramProperties } from './DiagramProperties'
import { LinkProperties } from './LinkProperties'
import { NodeProperties } from './NodeProperties'
import {
	DiagramPanel,
	OpenButton,
	OpenButtonIcon,
	OpenButtonText,
	Title,
	ToggleButton,
} from './styles'
import { DiagramPropertiesPanelProps } from './types'

export const DiagramPropertiesPanel = ({
	selectedNode,
	selectedLink,
	selectedDiagram,
	diagramRef,
	saveProperties,
	nodeId,
}: DiagramPropertiesPanelProps) => {
	const [isHidden, setIsHidden] = useState(true)
	const refContainer = useRef<HTMLDivElement>(null)
	const getAllNodesFromTree = useAppStore(
		(state: RootState) => state?.node?.nodes,
	)
	const { t } = useAppContext()
	const [propertiesTitle, setPropertiesTitle] = useState(
		t('DIAGRAM_PROPERTIES'),
	)
	const propertiesWidth = 300

	const toggleVisibility = () => {
		setIsHidden((prev) => !prev)
	}

	const handlePropertiesTitle = (title: string) => {
		setPropertiesTitle(title)
	}

	return (
		<>
			{isHidden && (
				<OpenButton as="button" onClick={toggleVisibility} role="button">
					<OpenButtonText>Properties</OpenButtonText>
					<OpenButtonIcon>
						<FontAwesomeIcon icon={faChevronLeft} color="#888" />
					</OpenButtonIcon>
				</OpenButton>
			)}
			{!isHidden && (
				<DiagramPanel role="complementary" ref={refContainer}>
					<Title onClick={toggleVisibility}>
						<ToggleButton>
							<FontAwesomeIcon icon={faChevronRight} color="#888" />
						</ToggleButton>
						{propertiesTitle}
					</Title>
					{selectedNode && (
						<NodeProperties
							selectedNode={selectedNode}
							getAllNodesFromTree={getAllNodesFromTree}
							diagramRef={diagramRef}
							propertiesWidth={propertiesWidth}
							handlePropertiesTitle={handlePropertiesTitle}
						/>
					)}
					{selectedLink && (
						<LinkProperties
							selectedLink={selectedLink}
							getAllNodesFromTree={getAllNodesFromTree}
							diagramRef={diagramRef}
							propertiesWidth={propertiesWidth}
							handlePropertiesTitle={handlePropertiesTitle}
						/>
					)}
					{selectedDiagram && (
						<DiagramProperties
							handlePropertiesTitle={(title: string) =>
								handlePropertiesTitle(title)
							}
							diagramRef={diagramRef}
							saveProperties={saveProperties}
							nodeId={nodeId}
						/>
					)}
				</DiagramPanel>
			)}
		</>
	)
}
