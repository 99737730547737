import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { StructureDto } from '@/endpoints/models'
import { LinkData } from '@/endpoints/schemas/diagram'
import { TableTab } from '@/enums'
import { useAppDispatch } from '@/hooks'
import { RootState } from '@/store'
import { StructureDtoRedux } from '@/store/modules/node'
import { openTab } from '@/store/modules/tab/actions'
import { selectTableTab } from '@/store/modules/table/actions'
import colors from '@/styles/diagramColors'
import { NativeMap } from '@/utils'

import { setupLinkTemplates } from './Templates'
import { ValidationError, ValidationErrorType } from './types'

const $ = go.GraphObject.make

export const initPalette = () => {
	const palette = $(go.Palette)

	palette.layout = $(go.GridLayout, { wrappingColumn: 1 })
	palette.maxSelectionCount = 1

	return palette
}

export const getNodePath = (
	allNodesFromTree: NativeMap<StructureDtoRedux>,
	nodeId: number,
): string => {
	const acc: string[] = []

	const buildPath = (currentId: number) => {
		const node = allNodesFromTree[currentId]
		if (!node) {
			return
		}
		if (node.parentStructureId) {
			buildPath(node.parentStructureId)
		}
		if (node.name !== undefined) {
			acc.push(node.name)
		}
	}

	buildPath(nodeId)
	return acc.join(' > ')
}

export const getLinksByNodeId = (
	state: RootState,
	nodeId: number,
): LinkData[] => {
	let matchingLinks: LinkData[] = []

	Object.values(state.folder.folders).forEach((folder) => {
		if (
			folder &&
			folder.form &&
			folder.form.newDiagram &&
			folder.form.newDiagram.linkDataArray
		) {
			const links = folder.form.newDiagram.linkDataArray.filter(
				(link) => link.from === nodeId || link.to === nodeId,
			)
			matchingLinks = matchingLinks.concat(links)
		}
	})

	return matchingLinks
}

/**

Marks validation errors in the diagram. For nodes and links

related to the error, the 'changed' property is set to true.

@param diagram - The current instance of the diagram.

@param validationErrors - Array of validation errors.
*/
export const markChanges = (
	diagram: ReactDiagram | null,
	validationErrors: ValidationError[],
) => {
	if (diagram) {
		const instanceDiagram = diagram.getDiagram()
		const model = instanceDiagram?.model as go.GraphLinksModel

		if (model && instanceDiagram) {
			instanceDiagram.startTransaction('mark changes')

			validationErrors.forEach((error) => {
				const { type, nodeId } = error

				// Mark changed nodes
				const node = model.findNodeDataForKey(nodeId)
				if (
					node &&
					(type === ValidationErrorType.NODE_NOT_EXIST ||
						type === ValidationErrorType.TEXT_MISMATCH)
				) {
					model.setDataProperty(node, 'hasChanged', true)
					model.setDataProperty(node, 'bodyColor', colors.nodes.hasChanged)
				}

				// Mark changed links
				const link = model.findLinkDataForKey(nodeId)
				if (
					link &&
					(type === ValidationErrorType.NODE_NOT_EXIST ||
						type === ValidationErrorType.TEXT_MISMATCH ||
						type === ValidationErrorType.LINK_INVALID)
				) {
					model.setDataProperty(link, 'hasChanged', true)
				}
			})

			instanceDiagram.commitTransaction('mark changes')
		}
	}
}

export const serializeDiagram = (diagram: go.Diagram) => {
	const diagramSvg = diagram.makeSvg()
	return diagramSvg
		? new XMLSerializer().serializeToString(diagramSvg)
		: undefined
}

const useOpenTable = (data: StructureDto) => {
	const dispatch = useAppDispatch()
	dispatch(openTab(data, false))
}

export const useHandleData = (data: StructureDto, isDoubleClick: boolean) => {
	const dispatch = useAppDispatch()
	useOpenTable(data)
	if (isDoubleClick) {
		setTimeout(() => {
			dispatch(selectTableTab(data, TableTab.Constraints))
		}, 2000)
	}
}

export const removeNode =
	(diagramRef: React.RefObject<ReactDiagram>) =>
	(nodeId: number): boolean => {
		const instanceDiagram = diagramRef.current?.getDiagram()
		if (!instanceDiagram) {
			throw new Error('Diagram instance is null')
		}

		const model = instanceDiagram.model as go.GraphLinksModel
		const nodeData = model.findNodeDataForKey(nodeId)
		if (!nodeData) {
			throw new Error(`Node with ID ${nodeId} was not found`)
		}
		instanceDiagram.startTransaction('remove node')

		// Remove node
		model.removeNodeData(nodeData)

		// Remove links related to the node
		const linksFromNode = instanceDiagram.findLinksByExample({ from: nodeId })
		linksFromNode.each((link) => {
			model.removeLinkData(link.data)
		})

		const linksToNode = instanceDiagram.findLinksByExample({ to: nodeId })
		linksToNode.each((link) => {
			model.removeLinkData(link.data)
		})

		instanceDiagram.commitTransaction('remove node')
		return true
	}

export const addNewNode =
	(
		getAllNodesFromTree: NativeMap<StructureDtoRedux>,
		setFolderNode: (nodeData: StructureDtoRedux | undefined) => void,
		nodeId: number,
		setIsAddNewNode: (isAddNewNode: boolean) => void,
	) =>
	() => {
		const fldrNode = getAllNodesFromTree[nodeId]
		if (!fldrNode) {
			throw new Error(`Node with ID ${nodeId} was not found`)
		}
		setFolderNode(fldrNode)
		setIsAddNewNode(true)
	}
export const deleteNode =
	(
		getAllNodesFromTree: NativeMap<StructureDtoRedux>,
		setShowDeleteModal: (show: boolean) => void,
		setNodeToDelete: (node: StructureDtoRedux | undefined) => void,
	) =>
	(nodeId: number) => {
		setShowDeleteModal(true)
		const nodeToDelete = getAllNodesFromTree[nodeId]
		if (!nodeToDelete) {
			throw new Error(`Node with ID ${nodeId} was not found`)
		}
		setNodeToDelete(nodeToDelete)
	}

export const updateNodeColor =
	(diagramRef: React.RefObject<ReactDiagram>) =>
	(nodeKey: number, bodyColor?: string, headerColor?: string) => {
		const instanceDiagram = diagramRef.current?.getDiagram() as go.Diagram

		if (!instanceDiagram) {
			throw new Error('Diagram instance is null')
		}

		instanceDiagram.startTransaction('update node color')
		const nodeData = instanceDiagram.model.findNodeDataForKey(nodeKey)

		if (!nodeData) {
			instanceDiagram.commitTransaction('update node color')
			return
		}

		instanceDiagram.model.setDataProperty(nodeData, 'bodyColor', bodyColor)
		instanceDiagram.model.setDataProperty(nodeData, 'headerColor', headerColor)
		instanceDiagram.commitTransaction('update node color')
	}

export const updateLinkColor =
	(diagramRef: React.RefObject<ReactDiagram>) =>
	(linkKey: number, color: string) => {
		const instanceDiagram = diagramRef.current?.getDiagram() as go.Diagram

		if (!instanceDiagram) {
			throw new Error('Diagram instance is null')
		}

		instanceDiagram.startTransaction('update link color')

		const link = instanceDiagram.findLinkForKey(linkKey)
		if (!link) {
			instanceDiagram.commitTransaction('update link color')
			return
		}

		instanceDiagram.model.setDataProperty(link.data, 'color', color)
		instanceDiagram.commitTransaction('update link color')
	}

export const updateNodeHeaderColor =
	(diagramRef: React.RefObject<ReactDiagram>) => (color: string) => {
		const diagram = diagramRef.current?.getDiagram()
		if (!diagram) {
			throw new Error('Diagram instance is null')
		}

		diagram.startTransaction('update node header background color')
		diagram.model.nodeDataArray.forEach((nodeData) => {
			if (!nodeData.hasChanged) {
				diagram.model.setDataProperty(nodeData, 'headerColor', color)
			}
		})
		diagram.commitTransaction('update node header background color')
	}

export const updateNodeBodyColor =
	(diagramRef: React.RefObject<ReactDiagram>) => (color: string) => {
		const diagram = diagramRef.current?.getDiagram()
		if (!diagram) {
			throw new Error('Diagram instance is null')
		}
		diagram.startTransaction('update node body background color')
		diagram.model.nodeDataArray.forEach((nodeData) => {
			if (!nodeData.hasChanged) {
				diagram.model.setDataProperty(nodeData, 'bodyColor', color)
			}
		})
		diagram.commitTransaction('update node body background color')
	}

export const updateAllLinksColor =
	(diagramRef: React.RefObject<ReactDiagram>) => (color: string) => {
		const diagram = diagramRef.current?.getDiagram()

		// Ensure diagram.model is of type go.GraphLinksModel
		const model = diagram?.model as go.GraphLinksModel

		if (!diagram) {
			throw new Error('Diagram instance is null')
		}

		diagram.startTransaction('update link color')
		model.linkDataArray.forEach((linkData) => {
			diagram.model.setDataProperty(linkData, 'color', color)
		})
		diagram.commitTransaction('update link color')
	}

export const updateGridVisibility =
	(diagramRef: React.RefObject<ReactDiagram>) => (showGrid: boolean) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			diagram.startTransaction('update grid visibility')
			diagram.grid.visible = showGrid
			diagram.commitTransaction('update grid visibility')
		}
	}
export const updateGridCellSize =
	(diagramRef: React.RefObject<ReactDiagram>) => (size: number) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			diagram.startTransaction('update grid cell size')
			diagram.grid.gridCellSize = new go.Size(size, size)
			diagram.commitTransaction('update grid cell size')
		}
	}

export const updateGridColors =
	(diagramRef: React.RefObject<ReactDiagram>) =>
	(
		horizontalLineColor: string,
		verticalLineColor: string,
		intervalHorizontalLineColor: string,
		intervalVerticalLineColor: string,
	) => {
		const diagram = diagramRef.current?.getDiagram()

		if (diagram) {
			diagram.startTransaction('update grid colors')

			// Update the horizontal line color
			const horizontalLine = diagram.grid.findObject(
				'HorizontalLine',
			) as go.Shape
			if (horizontalLine && horizontalLineColor) {
				horizontalLine.stroke = horizontalLineColor
			}

			// Update the vertical line color
			const verticalLine = diagram.grid.findObject('VerticalLine') as go.Shape
			if (verticalLine && verticalLineColor) {
				verticalLine.stroke = verticalLineColor
			}

			// Update the interval horizontal line color
			const intervalHorizontalLine = diagram.grid.findObject(
				'IntervalHorizontalLine',
			) as go.Shape
			if (intervalHorizontalLine && intervalHorizontalLineColor) {
				intervalHorizontalLine.stroke = intervalHorizontalLineColor
			}

			// Update the interval vertical line color
			const intervalVerticalLine = diagram.grid.findObject(
				'IntervalVerticalLine',
			) as go.Shape
			if (intervalVerticalLine && intervalVerticalLineColor) {
				intervalVerticalLine.stroke = intervalVerticalLineColor
			}

			diagram.commitTransaction('update grid colors')
		}
	}

export const updateTableCodeVisibility =
	(diagramRef: RefObject<ReactDiagram>) => (tableCodeVisibility: boolean) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			diagram.startTransaction('update table code visibility')
			const model = diagram.model as go.GraphLinksModel
			model.nodeDataArray.forEach((nodeData) => {
				model.setDataProperty(
					nodeData,
					'tableCodeVisibility',
					tableCodeVisibility,
				)
			})
			diagram.commitTransaction('update table code visibility')
		}
	}

export const updateTableNameVisibility =
	(diagramRef: RefObject<ReactDiagram>) => (tableNameVisibility: boolean) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			diagram.startTransaction('update table name visibility')
			const model = diagram.model as go.GraphLinksModel
			model.nodeDataArray.forEach((nodeData) => {
				model.setDataProperty(
					nodeData,
					'tableNameVisibility',
					tableNameVisibility,
				)
			})
			diagram.commitTransaction('update table name visibility')
		}
	}
export const updateColumnsNameVisibility =
	(diagramRef: RefObject<ReactDiagram>) => (columnsNameVisibility: boolean) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			const model = diagram.model as go.GraphLinksModel
			if (model) {
				diagram.startTransaction('update columns name visibility')
				model.nodeDataArray.forEach((nodeData) => {
					model.setDataProperty(
						nodeData,
						'columnsNameVisibility',
						columnsNameVisibility,
					)
				})
				diagram.commitTransaction('update columns name visibility')
			}
		}
	}

export const updateColumnsCodeVisibility =
	(diagramRef: RefObject<ReactDiagram>) => (columnsCodeVisibility: boolean) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			const model = diagram.model as go.GraphLinksModel
			if (model) {
				diagram.startTransaction('update columns code visibility')
				model.nodeDataArray.forEach((nodeData) => {
					model.setDataProperty(
						nodeData,
						'columnsCodeVisibility',
						columnsCodeVisibility,
					)
				})
				diagram.commitTransaction('update columns code visibility')
			}
		}
	}

export const updateAllLinksRoutingType =
	(diagramRef: React.RefObject<ReactDiagram>) => (linksRoutingType: string) => {
		const diagram = diagramRef.current?.getDiagram()
		if (!diagram) {
			throw new Error('Diagram instance is null')
		}

		diagram.startTransaction('update links routing type')

		const linkTemplates = setupLinkTemplates(
			undefined,
			undefined,
			linksRoutingType,
		)

		// Ensure diagram.model is of type go.GraphLinksModel
		const model = diagram?.model as go.GraphLinksModel

		diagram.linkTemplateMap = linkTemplates

		model.linkDataArray.forEach((linkData) => {
			diagram.model.setDataProperty(
				linkData,
				'linksRoutingType',
				linksRoutingType,
			)
		})
		diagram.commitTransaction('update links routing type')
	}

export const updateCommentsVisibility =
	(diagramRef: RefObject<ReactDiagram>) => (commentsVisibility: boolean) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			const model = diagram.model as go.GraphLinksModel
			if (model) {
				diagram.startTransaction('update comments visibility')
				model.nodeDataArray.forEach((nodeData) => {
					model.setDataProperty(
						nodeData,
						'commentsVisibility',
						commentsVisibility,
					)
				})
				diagram.commitTransaction('update comments visibility')
			}
		}
	}

export const updateDescriptionsVisibility =
	(diagramRef: RefObject<ReactDiagram>) =>
	(descriptionsVisibility: boolean) => {
		const diagram = diagramRef.current?.getDiagram()
		if (diagram) {
			const model = diagram.model as go.GraphLinksModel
			if (model) {
				diagram.startTransaction('update descriptions visibility')
				model.nodeDataArray.forEach((nodeData) => {
					model.setDataProperty(
						nodeData,
						'descriptionsVisibility',
						descriptionsVisibility,
					)
				})
				diagram.commitTransaction('update descriptions visibility')
			}
		}
	}
