import { useAppContext } from '@/hooks'
import { ChangeEvent } from 'react'
import { updateLinkColor } from './utils'
import { DiagramPropertiesContent } from './DiagramPropertiesContent'
import { LinkPropertiesProps } from './types'

export const LinkProperties = ({
	selectedLink,
	getAllNodesFromTree,
	diagramRef,
	propertiesWidth,
	handlePropertiesTitle,
}: LinkPropertiesProps) => {
	const handleLinkColorChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newColor = e.target.value
		if (diagramRef) {
			const updateLinkColorFn = updateLinkColor(diagramRef)
			updateLinkColorFn(selectedLink.key, newColor)
		} else {
			throw new Error('diagramRef is not defined')
		}
	}
	const { t } = useAppContext()
	handlePropertiesTitle(t('DIAGRAM_LINK_PROPERTIES'))
	const contents = [
		{ id: 1, label: t('DIAGRAM_NODE_ID'), value: selectedLink.key },
		{ id: 2, label: t('DIAGRAM_NODE_TEXT'), value: selectedLink.text },
		{
			id: 3,
			label: t('DIAGRAM_NODE_FROM'),
			value: getAllNodesFromTree[selectedLink.to]?.name,
		},
		{
			id: 4,
			label: t('DIAGRAM_NODE_TO'),
			value: getAllNodesFromTree[selectedLink.from]?.name,
		},
		{ id: 5, label: t('DIAGRAM_NODE_TYPE'), value: 'Link' },
		{
			id: 6,
			label: t('DIAGRAM_LINK_COLOR'),
			value: selectedLink.color || '#000',
			inputType: 'color',
			onChange: handleLinkColorChange,
		},
	]
	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
