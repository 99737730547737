import {  ValidationError, ValidationErrorType } from '@/components/Diagram/types'
import { useAppContext } from '@/hooks'
import styled from 'styled-components'

type CollaborativeUpdateTableProps = {
    errors: ValidationError[]
}

export const CollaborativeUpdateTable = ({ errors }: CollaborativeUpdateTableProps) => {
    const { t } = useAppContext()

    const getChangeType = (type: ValidationErrorType) => {
        switch (type) {
            case ValidationErrorType.NODE_NOT_EXIST:
                return t('DIAGRAM_VALIDATION_TABLE_NOT_EXIST')
            case ValidationErrorType.TEXT_MISMATCH:
                return t('DIAGRAM_VALIDATION_TEXT_MISMATCH')
            case ValidationErrorType.LINK_INVALID:
                return t('DIAGRAM_VALIDATION_LINK_NOT_EXIST')
            default:
                return ''
        }
    }
console.log(errors, 'errors')

    return (
        <StyledTable>
            <thead>
                <tr>
                    <StyledTh>{t('ELEMENT')}</StyledTh>
                    <StyledTh>{t('TYPE')}</StyledTh>
                    <StyledTh>{t('CHANGE')}</StyledTh>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(errors) && errors.map((error, index) => (
                    <StyledTr key={index}>
                        <StyledTd>{error.nodeName}</StyledTd>
                        <StyledTd>
                            {error.type === ValidationErrorType.LINK_INVALID
                                ? 'Link'
                                : 'Table'}
                        </StyledTd>
                        <StyledTd>{error.message}</StyledTd>
                    </StyledTr>
                ))}
            </tbody>
        </StyledTable>
    )
}

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`

const StyledTh = styled.th`
    border: 1px solid #ddd;
    padding: 8px;
    background-color: #f2f2f2;
    text-align: left;
`

const StyledTd = styled.td`
    border: 1px solid #ddd;
    padding: 8px;
`

const StyledTr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
    
    &:hover {
        background-color: #ddd;
    }
`