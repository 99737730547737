import { StructureDetailDto, StructureDto } from '@/endpoints/models'
import { DiagramData, NewDiagramData } from '@/endpoints/schemas/diagram'
import { CustomAttributeForm } from '@/hooks'
import { GenerateCodeEnum } from '@/types'

import {
	ADD_NEW_DIAGRAM,
	DIAGRAM_UPDATE,
	FOLDER_INIT,
	FOLDER_SAVE,
	FOLDER_UPDATE,
	REMOVE_DIAGRAM,
} from './constants'

export type FolderInit = {
	editMode: boolean
	force: boolean
	node: StructureDetailDto
	type: typeof FOLDER_INIT
}

type FolderUpdate = {
	node: StructureDto
	payload: FolderData
	type: typeof FOLDER_UPDATE
}
type AddNewDiagram = {
	nodeId: number
	type: typeof ADD_NEW_DIAGRAM
	update: any
}
type RemoveDiagram = {
	node: StructureDto
	type: typeof REMOVE_DIAGRAM
	update: any
}

export type FolderSave = {
	metadata: {
		nodeId: number
	}
	payload: void
	type: typeof FOLDER_SAVE
}

interface DiagramUpdatePayload {
	diagramSvg: string
	modifiedDiagramModel: any
	selectedTabDetailId: number
}

interface UpdateDiagram {
	node: StructureDto
	payload: DiagramUpdatePayload
	type: typeof DIAGRAM_UPDATE
}

export interface OpenedFolderData {
	dirty: boolean
	form: FolderDataForm
	parsedEditMode: boolean
	tab: OpenedFolderTab
}

export type FolderDataForm = Omit<Partial<FolderData>, 'namingConfig'> & {
	generateCode?: GenerateCodeEnum
	generateTableColumnCode?: GenerateCodeEnum
}

export interface FolderData {
	customAttributes?: CustomAttributeForm[]
	diagram: DiagramData
	diagramSvg?: string
	diagrams: NewDiagramData[]
	name: string
	namingConfig?: {
		generateCode?: GenerateCodeEnum
		generateTableColumnCode?: GenerateCodeEnum
	}
	newDiagram: NewDiagramData
	ownerId?: number
	ownerName?: string
	stereotypeCode?: string
	stereotypeId?: number
}

export enum OpenedFolderTab {
	Dependencies = 'Dependencies',
	Diagram = 'Diagram',
	Diagrams = 'Diagrams',
	Overview = 'Overview',
	Validation = 'Validation',
}

export type Action =
	| FolderInit
	| FolderUpdate
	| FolderSave
	| AddNewDiagram
	| RemoveDiagram
	| UpdateDiagram
