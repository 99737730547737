import { ReactDiagram } from 'gojs-react'
import { ChangeEvent, useEffect, useState } from 'react'

import { useAppContext, useAppStore } from '@/hooks'
import { RootState } from '@/store'
import colors from '@/styles/diagramColors'
import mainColors from '@/styles/mainColors'

import { DiagramPropertiesContent } from './DiagramPropertiesContent'
import { RoutingTypeSelector } from './RoutingTypeSelector'
import { DiagramPropertiesProps, LinkRoutingType } from './types'
import {
	updateAllLinksColor,
	updateAllLinksRoutingType,
	updateColumnsCodeVisibility,
	updateColumnsNameVisibility,
	updateCommentsVisibility,
	updateDescriptionsVisibility,
	updateGridCellSize,
	updateGridColors,
	updateGridVisibility,
	updateNodeBodyColor,
	updateNodeHeaderColor,
	updateTableCodeVisibility,
	updateTableNameVisibility,
} from './utils'
export const DiagramProperties = ({
	handlePropertiesTitle,
	diagramRef,
	saveProperties,
	nodeId,
}: DiagramPropertiesProps) => {
	const { t } = useAppContext()

	const [nodeHeaderColor, setNodeHeaderColor] = useState<string>(
		mainColors.primary,
	)
	const [nodeBodyColor, setNodeBodyColor] = useState<string>(
		colors.nodes.bodyColor,
	)
	const [allLinksColor, setAllLinksColor] = useState<string>(
		colors.links.default,
	)
	const [isGridVisible, setIsGridVisible] = useState<boolean>(true)
	const [gridCellSize, setGridCellSize] = useState<number>(10)
	const [horizontalLineColor, setHorizontalLineColor] = useState<string>(
		colors.grid.horizontalLineColor,
	)
	const [verticalLineColor, setVerticalLineColor] = useState<string>(
		colors.grid.verticalLineColor,
	)
	const [intervalHorizontalLineColor, setIntervalHorizontalLineColor] =
		useState<string>(colors.grid.intervalHorizontalLineColor)
	const [intervalVerticalLineColor, setIntervalVerticalLineColor] =
		useState<string>(colors.grid.intervalVerticalLineColor)
	const [isTableCodeVisible, setIsTableCodeVisible] = useState<boolean>(true)
	const [isTableNameVisible, setIsTableNameVisible] = useState<boolean>(true)
	const [isPkColumnsNameVisible, setIsPkColumnsNameVisible] =
		useState<boolean>(true)
	const [isPkColumnsCodeVisible, setIsPkColumnsCodeVisible] =
		useState<boolean>(true)
	const [isColumnsNameVisible, setIsColumnsNameVisible] =
		useState<boolean>(true)
	const [isColumnsCodeVisible, setIsColumnsCodeVisible] =
		useState<boolean>(true)

	const [isCommentsVisible, setIsCommentsVisible] = useState<boolean>(true)
	const [isDescriptionVisible, setIsDescriptionVisible] =
		useState<boolean>(true)

	const [routingType, setRoutingType] = useState<string>(LinkRoutingType.Direct)

	handlePropertiesTitle(t('DIAGRAM_PROPERTIES'))

	const selectedDiagram = useAppStore(
		(state: RootState) => state?.folder?.folders[nodeId]?.form.newDiagram,
	)

	//set selected values in diagram properties panel
	useEffect(() => {
		if (selectedDiagram) {
			setGridCellSize(selectedDiagram?.properties?.gridCellSize)
			setHorizontalLineColor(selectedDiagram?.properties?.horizontalLineColor)
			setVerticalLineColor(selectedDiagram?.properties?.verticalLineColor)
			setIntervalHorizontalLineColor(
				selectedDiagram?.properties?.intervalHorizontalLineColor,
			)
			setIntervalVerticalLineColor(
				selectedDiagram?.properties?.intervalVerticalLineColor,
			)
			setIsGridVisible(selectedDiagram?.properties?.gridVisible),
				setIsTableCodeVisible(selectedDiagram.properties?.tableCodeVisibility)
			setIsTableNameVisible(selectedDiagram.properties?.tableNameVisibility)
			setIsGridVisible(selectedDiagram?.properties?.gridVisible)
			setIsTableCodeVisible(selectedDiagram.properties?.tableCodeVisibility)
			setIsTableNameVisible(selectedDiagram.properties?.tableNameVisibility)
			setIsPkColumnsNameVisible(
				selectedDiagram.properties?.pkColumnsNameVisibility,
			)
			setIsPkColumnsCodeVisible(
				selectedDiagram.properties?.pkColumnsCodeVisibility,
			)
			setIsColumnsNameVisible(selectedDiagram.properties?.columnsNameVisibility)
			setIsColumnsCodeVisible(selectedDiagram.properties?.columnsCodeVisibility)
			setRoutingType(
				selectedDiagram.properties?.routingType || LinkRoutingType.Direct,
			)
			setIsCommentsVisible(selectedDiagram.properties?.commentsVisibility)
			setIsDescriptionVisible(
				selectedDiagram?.properties?.descriptionVisibility,
			)
		}
	}, [selectedDiagram])

	const handleUpdateNodeHeaderColor = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const color = e.target.value
		setNodeHeaderColor(color)
		updateNodeHeaderColor(diagramRef)(color)
	}

	const handleUpdateNodeBodyColor = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const color = e.target.value
		setNodeBodyColor(color)
		updateNodeBodyColor(diagramRef)(color)
	}

	const handleUpdateAllLinksColor = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const color = e.target.value
		setAllLinksColor(color)
		updateAllLinksColor(diagramRef)(color)
	}

	const handleGridToggle = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = e.target.checked
		setIsGridVisible(isChecked)
		updateGridVisibility(diagramRef)(isChecked)
		if (saveProperties) {
			saveProperties({ gridVisible: isChecked })
		}
	}
	const handleGridCellSizeChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const newSize = parseInt(e.target.value)
		setGridCellSize(newSize)
		updateGridCellSize(diagramRef)(newSize)
		if (saveProperties) {
			saveProperties({ gridCellSize: newSize })
		}
	}

	const handleHorizontalLineColorChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const newColor = e.target.value
		setHorizontalLineColor(newColor)
		updateGridColors(diagramRef)(
			newColor,
			verticalLineColor,
			intervalHorizontalLineColor,
			intervalVerticalLineColor,
		)
		if (saveProperties) {
			saveProperties({ horizontalLineColor: newColor })
		}
	}

	const handleVerticalLineColorChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const newColor = e.target.value
		setVerticalLineColor(newColor)
		updateGridColors(diagramRef)(
			horizontalLineColor,
			newColor,
			intervalHorizontalLineColor,
			intervalVerticalLineColor,
		)
		if (saveProperties) {
			saveProperties({ verticalLineColor: newColor })
		}
	}

	const handleIntervalHorizontalLineColorChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const newColor = e.target.value
		setIntervalHorizontalLineColor(newColor)
		updateGridColors(diagramRef)(
			horizontalLineColor,
			verticalLineColor,
			newColor,
			intervalVerticalLineColor,
		)
		if (saveProperties) {
			saveProperties({ intervalHorizontalLineColor: newColor })
		}
	}

	const handleIntervalVerticalLineColorChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const newColor = e.target.value
		setIntervalVerticalLineColor(newColor)
		updateGridColors(diagramRef)(
			horizontalLineColor,
			verticalLineColor,
			intervalHorizontalLineColor,
			newColor,
		)
		if (saveProperties) {
			saveProperties({ intervalVerticalLineColor: newColor })
		}
	}

	const handleTableCodeChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = e.target.checked
		setIsTableCodeVisible(isChecked)

		if (saveProperties) {
			saveProperties({ tableCodeVisibility: isChecked })
		}
		updateTableCodeVisibility(diagramRef)(isChecked)
	}

	const handleTableNameChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = e.target.checked
		setIsTableNameVisible(isChecked)

		if (saveProperties) {
			saveProperties({ tableNameVisibility: isChecked })
		}
		updateTableNameVisibility(diagramRef)(isChecked)
	}

	const handleColumnsNameChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = e.target.checked
		setIsColumnsNameVisible(isChecked)

		if (saveProperties) {
			saveProperties({ columnsNameVisibility: isChecked })
		}
		updateColumnsNameVisibility(diagramRef)(isChecked)
	}

	const handleColumnsCodeChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = e.target.checked
		setIsColumnsCodeVisible(isChecked)

		if (saveProperties) {
			saveProperties({ columnsCodeVisibility: isChecked })
		}
		updateColumnsCodeVisibility(diagramRef)(isChecked)
	}

	const handleRoutingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newRoutingType = e.target.value
		setRoutingType(newRoutingType)
		if (saveProperties) {
			saveProperties({ routingType: newRoutingType })
		}
		updateAllLinksRoutingType(diagramRef)(newRoutingType)
	}

	const handleCommentsChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = e.target.checked
		setIsCommentsVisible(isChecked)

		if (saveProperties) {
			saveProperties({ commentsVisibility: isChecked })
		}
		updateCommentsVisibility(diagramRef)(isChecked)
	}

	const handleDescriptionsChange = (
		diagramRef: React.RefObject<ReactDiagram>,
		e: ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = e.target.checked
		setIsDescriptionVisible(isChecked)

		if (saveProperties) {
			saveProperties({ descriptionVisibility: isChecked })
		}
		updateDescriptionsVisibility(diagramRef)(isChecked)
	}

	const contents = [
		{
			id: 1,
			label: t('DIAGRAM_NODE_NAME_BACKGROUND'),
			value: (
				<input
					type="color"
					value={nodeHeaderColor}
					onChange={(e) => handleUpdateNodeHeaderColor(diagramRef, e)}
				/>
			),
		},
		{
			id: 2,
			label: t('DIAGRAM_NODE_COLUMNS_BACKGROUND'),
			value: (
				<input
					type="color"
					value={nodeBodyColor}
					onChange={(e) => handleUpdateNodeBodyColor(diagramRef, e)}
				/>
			),
		},
		{
			id: 3,
			label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
			value: (
				<input
					type="color"
					value={allLinksColor}
					onChange={(e) => handleUpdateAllLinksColor(diagramRef, e)}
				/>
			),
		},
		{
			id: 4,
			label: t('DIAGRAM_GRID_SWITCH'),
			value: (
				<input
					type="checkbox"
					checked={isGridVisible}
					onChange={(e) => handleGridToggle(diagramRef, e)}
				/>
			),
		},
		...(isGridVisible
			? [
					{
						id: 5,
						label: t('DIAGRAM_GRID_CELL_SIZE'),
						value: (
							<input
								type="range"
								min="5"
								max="50"
								value={gridCellSize}
								onChange={(e) => handleGridCellSizeChange(diagramRef, e)}
							/>
						),
					},
					{
						id: 6,
						label: t('DIAGRAM_GRID_HORIZONTAL_LINE'),
						value: (
							<input
								type="color"
								value={horizontalLineColor}
								onChange={(e) => handleHorizontalLineColorChange(diagramRef, e)}
							/>
						),
					},
					{
						id: 7,
						label: t('DIAGRAM_GRID_VERTICAL_LINE'),
						value: (
							<input
								type="color"
								value={verticalLineColor}
								onChange={(e) => handleVerticalLineColorChange(diagramRef, e)}
							/>
						),
					},
					{
						id: 8,
						label: t('DIAGRAM_GRID_INTERVAL_HORIZONTAL_LINE'),
						value: (
							<input
								type="color"
								value={intervalHorizontalLineColor}
								onChange={(e) =>
									handleIntervalHorizontalLineColorChange(diagramRef, e)
								}
							/>
						),
					},
					{
						id: 9,
						label: t('DIAGRAM_GRID_INTERVAL_VERTICAL_LINE'),
						value: (
							<input
								type="color"
								value={intervalVerticalLineColor}
								onChange={(e) =>
									handleIntervalVerticalLineColorChange(diagramRef, e)
								}
							/>
						),
					},
				]
			: []),
		{
			id: 10,
			label: t('DIAGRAM_TABLE_CODE'),
			value: (
				<input
					type="checkbox"
					checked={isTableCodeVisible}
					onChange={(e) => handleTableCodeChange(diagramRef, e)}
				/>
			),
		},
		{
			id: 11,
			label: t('DIAGRAM_TABLE_NAME'),
			value: (
				<input
					type="checkbox"
					checked={isTableNameVisible}
					onChange={(e) => handleTableNameChange(diagramRef, e)}
				/>
			),
		},
		{
			id: 12,
			label: t('DIAGRAM_PK_CODE'),
			value: <input type="checkbox" checked={isPkColumnsCodeVisible} />,
		},
		{
			id: 13,
			label: t('DIAGRAM_PK_NAME'),
			value: <input type="checkbox" checked={isPkColumnsNameVisible} />,
		},
		{
			id: 14,
			label: t('DIAGRAM_COLUMNS_CODE'),
			value: (
				<input
					type="checkbox"
					checked={isColumnsCodeVisible}
					onChange={(e) => handleColumnsCodeChange(diagramRef, e)}
				/>
			),
		},
		{
			id: 15,
			label: t('DIAGRAM_COLUMNS_NAME'),
			value: (
				<input
					type="checkbox"
					checked={isColumnsNameVisible}
					onChange={(e) => handleColumnsNameChange(diagramRef, e)}
				/>
			),
		},
		{
			id: 16,
			label: t('DIAGRAM_NODES_COMMENTS'),
			value: (
				<input
					type="checkbox"
					checked={isCommentsVisible}
					onChange={(e) => handleCommentsChange(diagramRef, e)}
				/>
			),
		},
		{
			id: 17,
			label: t('DIAGRAM_NODES_DESCRIPTION'),
			value: (
				<input
					type="checkbox"
					checked={isDescriptionVisible}
					onChange={(e) => handleDescriptionsChange(diagramRef, e)}
				/>
			),
		},
	]

	return (
		<>
			<DiagramPropertiesContent contents={contents} propertiesWidth={350} />
			<RoutingTypeSelector
				routingType={routingType}
				handleRoutingTypeChange={handleRoutingTypeChange}
				t={t}
			/>
		</>
	)
}
