import { DiagramPropertiesContent } from './DiagramPropertiesContent'
import { NodePropertiesProps, NodeProperty } from './types'
import { getNodePath, updateNodeColor } from './utils'
import { useAppContext } from '@/hooks'
import { ChangeEvent } from 'react'

export const NodeProperties = ({
	selectedNode,
	getAllNodesFromTree,
	diagramRef,
	propertiesWidth,
	handlePropertiesTitle,
}: NodePropertiesProps) => {
	const handleBodyColorChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newColor = e.target.value
		if (diagramRef) {
			const updateNodeColorFn = updateNodeColor(diagramRef)
			updateNodeColorFn(selectedNode.key, newColor, selectedNode.headerColor)
		} else {
			throw new Error('diagramRef is not defined')
		}
	}
	const handleHeaderColorChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newColor = e.target.value
		if (diagramRef) {
			const updateNodeColorFn = updateNodeColor(diagramRef)
			updateNodeColorFn(selectedNode.key, selectedNode.bodyColor, newColor)
		} else {
			throw new Error('diagramRef is not defined')
		}
	}
	const { t } = useAppContext()
	handlePropertiesTitle(t('DIAGRAM_NODE_PROPERTIES'))

	const contents: NodeProperty[] = [
		{ id: 1, label: t('DIAGRAM_NODE_ID'), value: selectedNode.key },
		{ id: 2, label: t('DIAGRAM_NODE_NAME'), value: selectedNode.text },
		{ id: 3, label: t('DIAGRAM_NODE_TYPE'), value: selectedNode.category },
		{
			id: 4,
			label: t('DIAGRAM_NODE_FULL_PATH'),
			value:
				getNodePath(getAllNodesFromTree, selectedNode.key) ||
				t('DIAGRAM_NODE_DELETED'),
		},
	]

	if (!selectedNode.hasChanged) {
		contents.push(
			{
				id: 5,
				label: t('DIAGRAM_NODE_HEADER_COLOR'),
				value: selectedNode.headerColor,
				inputType: 'color',
				onChange: handleHeaderColorChange,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_BODY_COLOR'),
				value: selectedNode.bodyColor,
				inputType: 'color',
				onChange: handleBodyColorChange,
			},
		)
	}
	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
