import { useEffect, useState } from 'react'

import { ParsedLink, ParsedNode } from '@/components/Diagram/types'
import { getDeepDiagram } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { useAppStore } from '@/hooks'

/**
 * Custom hook that parses nodes and links from a deeply nested diagram structure.
 *
 * @param {number} nodeId - The unique identifier of the node.
 * @param {number} selectedTabDetailId - The unique identifier of the selected tab detail.
 * @returns {{ parsedNodes: ParsedNode[], parsedLinks: ParsedLink[] }} Object containing parsed nodes and links, suitable for use with GoJS.
 *
 * @example
 * const { parsedNodes, parsedLinks } = useParseNodesAndLinks(nodeId, selectedTabDetailId);
 */
export const useParseNodesAndLinks = (
	nodeId: number,
	selectedTabDetailId: number,
) => {
	const [deepDiagram] = useApi(getDeepDiagram(nodeId))
	const diagrams = useAppStore(
		(state) => state.folder.folders[nodeId]?.form.diagrams,
	)
	const thisDiagram = diagrams?.find(
		(diagram) => diagram.id === selectedTabDetailId,
	)
	const [model, setModel] = useState(thisDiagram)
	const [parsedNodes, setParsedNodes] = useState<ParsedNode[]>([])
	const [parsedLinks, setParsedLinks] = useState<ParsedLink[]>([])

	useEffect(() => {
		setModel(thisDiagram)
	}, [thisDiagram])

	useEffect(() => {
		if (model) {
			const diagramFormLinks = model.linkDataArray
			const diagramFormNodes = model.nodeDataArray

			const newParsedNodes: ParsedNode[] =
				deepDiagram?.nodes?.map((deepDiagramNode) => {
					const matchedDiagramFormNode = diagramFormNodes?.find(
						(node) => node.key === deepDiagramNode.id,
					)
					return {
						key: deepDiagramNode.id,
						category: 'table',
						...matchedDiagramFormNode,
					}
				}) || []

			const newParsedLinks: ParsedLink[] =
				deepDiagram?.links?.map((deepLink) => {
					const matchedDiagramFormLink = diagramFormLinks?.find(
						(diagramFormLink) =>
							diagramFormLink.from === deepLink.primaryKeyTableId,
					)
					return {
						from: deepLink.foreignKeyTableId,
						to: deepLink.primaryKeyTableId,
						text: deepLink.constraintCode,
						hasChanged: matchedDiagramFormLink?.hasChanged,
						color: matchedDiagramFormLink?.color,
						routingType: matchedDiagramFormLink?.routingType,
					} as ParsedLink
				}) || []

			setParsedNodes(newParsedNodes)
			setParsedLinks(newParsedLinks)
		}
	}, [model, deepDiagram])

	return { parsedNodes, parsedLinks }
}
